
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import { BIconList } from "bootstrap-icons-vue";
import AppAccount from "@/components/AppAccount.vue";
import AppBreadcrumb from "@/components/AppBreadcrumb.vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    InlineSvg,
    BIconList,
    AppAccount,
    AppBreadcrumb,
  },
});
