import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_toast = _resolveComponent("app-toast")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "fade",
    tag: "div",
    class: "toaster toast-container p-3 position-fixed top-0 end-0"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.toast.toasts, (toast) => {
        return (_openBlock(), _createBlock(_component_app_toast, {
          key: toast,
          toast: toast
        }, null, 8, ["toast"]))
      }), 128))
    ]),
    _: 1
  }))
}