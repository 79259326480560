
import { defineComponent } from "vue";
import AppToaster from "@/components/AppToaster.vue";
import { mapState } from "vuex";
import "@fontsource/saira-semi-condensed/400.css";
import "@fontsource/saira-semi-condensed/500.css";
import "@fontsource/saira-semi-condensed/700.css";

export default defineComponent({
  components: { AppToaster },
  computed: {
    ...mapState("meta", ["title"]),
  },
  watch: {
    title(title: string) {
      if (title) {
        document.title = `${title} - Planer Ślubu`;
      } else {
        document.title = "Planer Ślubu";
      }
    },
  },
  created() {
    if (this.$store.state.meta.title) {
      document.title = `${this.$store.state.meta.title} - Planer Ślubu`;
    } else {
      document.title = "Planer Ślubu";
    }
  },
});
