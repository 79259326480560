import { MutationPayload, Plugin, Store } from "vuex";
import deepmerge from "deepmerge";

interface PersistenceSyncOptions<S> {
  storage?: Storage;
  key?: string;
  sync?: boolean;
  modules?: string[];
  synced?: (store: Store<S>, module: string, key: string) => void;
}

export class VuexPersistenceSync<S> implements PersistenceSyncOptions<S> {
  public storage: Storage;
  public key: string;
  public sync: boolean;
  public restoreState: (key: string, storage: Storage) => S;
  public saveState: (key: string, state: unknown, storage: Storage) => void;
  public reducer: (state: S) => Partial<S>;
  public plugin: Plugin<S>;

  public constructor(options?: PersistenceSyncOptions<S>) {
    if (typeof options === "undefined") {
      options = {} as PersistenceSyncOptions<S>;
    }
    this.key = options.key != null ? options.key : "vuex";

    let localStorageLitmus = true;
    try {
      window.localStorage.getItem("");
    } catch (err) {
      localStorageLitmus = false;
    }
    if (options.storage) {
      this.storage = options.storage;
    } else if (localStorageLitmus) {
      this.storage = window.localStorage;
    } else {
      throw new Error("localStorage is not available");
    }

    this.sync = options.sync != null ? options.sync : true;

    this.restoreState = (key: string, storage: Storage): S => {
      const value = storage.getItem(key);
      try {
        return JSON.parse(value || "{}");
      } catch (err) {
        return {} as S;
      }
    };

    this.saveState = (key: string, state: unknown, storage: Storage) => {
      storage.setItem(key, JSON.stringify(state));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.reducer = (state: any) =>
      (options?.modules as string[]).reduce(
        (a, i) =>
          deepmerge(
            a,
            { [i]: state[i] },
            {
              arrayMerge: (destinationArray, sourceArray) => sourceArray,
            }
          ),
        {
          /* start empty accumulator*/
        }
      );

    this.plugin = (store: Store<S>) => {
      const savedState = this.restoreState(this.key, this.storage) as S;
      store.replaceState(
        deepmerge(store.state, savedState || {}, {
          arrayMerge: (destinationArray, sourceArray) => sourceArray,
        }) as S
      );

      store.subscribe((mutation: MutationPayload, state: S) => {
        this.saveState(this.key, this.reducer(state), this.storage);
      });

      if (this.sync) {
        window.addEventListener("storage", (event: StorageEvent) => {
          if (event.key !== this.key) {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const savedState: { [key: string]: any } = this.restoreState(
            this.key,
            this.storage
          );
          if (options?.modules) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state: { [key: string]: any } = store.state;
            for (const module of options?.modules) {
              for (const key of Object.keys(state[module])) {
                if (module in savedState && key in savedState[module]) {
                  if (
                    savedState[module][key] !== null &&
                    typeof savedState[module][key] === "object"
                  ) {
                    state[module][key] = deepmerge(
                      state[module][key],
                      savedState[module][key] || {},
                      {
                        arrayMerge: (destinationArray, sourceArray) =>
                          sourceArray,
                      }
                    );
                  } else {
                    state[module][key] = savedState[module][key];
                  }
                  if (options.synced) {
                    options.synced(store, module, key);
                  }
                }
              }
            }
          }
        });
      }
    };
  }
}

export default VuexPersistenceSync;
