import { createStore } from "vuex";
import theme from "@/store/modules/theme";
import auth from "@/store/modules/auth";
import meta from "@/store/modules/meta";
import toast from "@/store/modules/toast";
import VuexPersistenceSync from "@/store/plugins/VuexPersistenceSync";

export default createStore({
  state: {} as { [key: string]: { [key: string]: unknown } },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    theme,
    auth,
    meta,
    toast,
  },
  plugins: [
    new VuexPersistenceSync({ key: "store", modules: ["theme", "auth"] })
      .plugin,
  ],
});
