
import { defineComponent } from "vue";
import { CContainer } from "@coreui/vue";
import AppSidebar from "@/components/AppSidebar.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    AppSidebar,
    AppHeader,
    AppFooter,
    CContainer,
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    $route(to, from) {
      this.visible = false;
    },
  },
});
