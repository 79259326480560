export interface MetaState {
  title: string;
}

export default {
  namespaced: true,
  state: {
    title: "",
  } as MetaState,
  mutations: {
    setTitle(state: MetaState, title: string) {
      state.title = title;
    },
  },
  actions: {},
};
