const filters = {
  date(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleDateString();
  },
  time(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleTimeString();
  },
  dateTime(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleString();
  },
};

export default filters;
