
import { defineComponent } from "vue";
import AppToast from "@/components/AppToast.vue";

export default defineComponent({
  name: "AppToaster",
  components: {
    AppToast,
  },
});
