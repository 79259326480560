import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper d-flex flex-column min-vh-100 bg-light" }
const _hoisted_2 = { class: "body flex-grow-1 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CContainer = _resolveComponent("CContainer")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppSidebar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppHeader),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CContainer, { fluid: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                _createVNode(_Transition, {
                  name: "fade",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_AppFooter)
    ])
  ]))
}