
import { defineComponent } from "vue";
import { RouteLocationMatched } from "vue-router";

export default defineComponent({
  name: "AppBreadcrumb",
  data() {
    return {
      breadcrumbs: [] as unknown,
    };
  },
  methods: {
    getBreadcrumbs() {
      return this.$router.currentRoute.value.matched.map(
        (route: RouteLocationMatched) => {
          return {
            active: route.path === this.$router.currentRoute.value.fullPath,
            name: route.meta.name,
            route: route.name,
          };
        }
      );
    },
  },
  created() {
    this.breadcrumbs = this.getBreadcrumbs();

    this.$router.afterEach(() => {
      this.breadcrumbs = this.getBreadcrumbs();
    });
  },
});
