export interface Toast {
  type: string;
  message: string;
}

export interface ToastState {
  toasts: Toast[];
}

export default {
  namespaced: true,
  state: {
    toasts: [],
  } as ToastState,
  mutations: {
    add(state: ToastState, toast: Toast) {
      state.toasts.push(toast);
    },
    remove(state: ToastState, toast: Toast) {
      state.toasts = state.toasts.filter((item: Toast) => item !== toast);
    },
    clear(state: ToastState) {
      state.toasts = [];
    },
  },
  actions: {},
};
