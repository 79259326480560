
import { defineComponent } from "vue";
import {
  BIconCheckCircleFill,
  BIconXCircleFill,
  BIconExclamationCircleFill,
  BIconInfoCircleFill,
} from "bootstrap-icons-vue";
import { Toast } from "@/store/modules/toast";

export default defineComponent({
  name: "AppToast",
  components: {
    BIconCheckCircleFill,
    BIconXCircleFill,
    BIconExclamationCircleFill,
    BIconInfoCircleFill,
  },
  props: {
    toast: {
      type: Object as () => Toast,
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 10000,
    },
  },
  methods: {
    getIcon(type: string) {
      switch (type) {
        case "success":
          return BIconCheckCircleFill;
        case "danger":
          return BIconXCircleFill;
        case "warning":
          return BIconExclamationCircleFill;
        case "info":
          return BIconInfoCircleFill;
      }
      return null;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("toast/remove", this.toast);
    }, this.duration);
  },
});
